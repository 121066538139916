/* ==================
	1. Basic
=================== */
* {
    outline: none;
    padding: 0;

    &::after {
        margin : 0;
        padding: 0;
    }

    &::before {
        margin : 0;
        padding: 0;
    }
}
body {
    line-height: 1.7;
	color:$body-color;

    &.fixed {
        overflow: hidden;
        position: fixed;
    } 
}

// Heading
h1, .h1{
	font-size: $h1-font-size;
	font-weight:700;
	@include respond('laptop-small'){
		font-size: 42px;
	}
	@include respond('phone-land'){
		font-size: 38px;
	}
	@include respond('phone'){
		font-size: 35px;
	}
}
h2, .h2{
	font-size: $h2-font-size;
	font-weight:700;
	@include respond('laptop-small'){
		font-size: 36px;		
	}
	@include respond('phone-land'){
		font-size: 32px;
	}
	@include respond('phone'){
		font-size: 28px;
	}
}
h3, .h3{
	font-size: $h3-font-size;
	font-weight:700;
	@include respond('phone-land'){
		font-size: 24px;
	}
}
h4, .h4{
	font-size: $h4-font-size;
	font-weight:700;
	@include respond('phone-land'){
		font-size: 20px;
	}
}
h5, .h5{
	font-size: $h5-font-size;
	font-weight:700;
	@include respond('phone'){
		font-size: 18px;
	}
}
h6, .h6{
	font-size: $h6-font-size;
	font-weight:700;
	@include respond('phone'){
		font-size: 16px;
	}
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
	font-family: $font-family-title;
	color: $dark;
	line-height:1.3	;
	a{
		color: $dark;
	}
}
ul {
    padding: 0;
    margin : 0;
}
img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
li {
    list-style: none;
}
a {
    // color       : $td;
    text-decoration: none;
    outline        : none;
	color          : $primary;	

    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        outline: none;
		color:$primary;
    }
}
b,
strong,
.strong {
    font-weight: 500;
    color      : $headings-color;
}
.h-0 {
    height: 0;
}
#main-wrapper{
    opacity       : 0;
    transition    : all 0.25s ease-in;
    overflow      : hidden;
    position      : relative;
    z-index       : 1;
    // height     : 100vh;
    // padding-top: 75px;
    margin-top    : 60px;

    &.show {
        opacity: 1;
    }
}
.content-body {
    margin-left  : 65px;
    margin-top   : 18px;
    margin-right : 6px;
    margin-bottom: 45px;
}
.bg-gray{
	background-color:#fafafb;
}

// Container
.container-sm{
	max-width: 990px!important;
}
.container-fluid{
	padding-left: 30px;
    padding-right: 30px;
	
	@include respond('big-desktop'){
		max-width: 1720px;
		padding-left: 15px;
		padding-right: 15px;
	}
	@include respond('tab-port'){
		padding-left: 15px;
		padding-right: 15px;
	}
}
.rounded{
	border-radius: $border-radius-base!important;
}