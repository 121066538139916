/* ==================
	8.10 Filter
=================== */
.filter-mobile{
	display:flex;
	margin-bottom:30px;
	display: none;
	border: 1px solid rgba(0,0,0,0.1);
	align-items: center;
	text-align: center;
	position: sticky;
	top: 80px;
	background: #fff;
	box-shadow: 0px 0px 10px 0 rgba(0,0,0,0.2);
	.filter-bx{
		&:first-child{
			border-right: 1px solid rgba(0,0,0,0.1);
		}
	}
	 .filter-bx{
		width:50%;
		padding: 10px;
	}
}
.filter-btn {
	i{
		margin-right:5px;
	}
	.close {
		display:none;
		opacity:0;
		&:hover{
			background:rgba(255,255,255,0.1);
		}
	}
}
.filter-bx {
	.bootstrap-select{
		.dropdown-toggle{
			max-width:100px;	
			border: 2px solid rgba(0,0,0,0.1) !important;
			border-radius: 4px;
			padding: 2px 10px;
			color: #404040;
			font-weight: 600;
			height: 32px;
			.caret{
				top: 9px;
			}
		}
	}
}

.filter-on {
	.filter-btn.close {
		border: 0;
		z-index: 1;
		position: fixed;
		right: 10px;
		top: 10px;
		color: $primary;
		opacity: 1;
		font-size: 18px;
		display:block;
		transition:all 0.5s;
		-moz-transition:all 0.5s;
		-moz-transition:all 0.5s;
		-ms-transition:all 0.5s;
		-o-transition:all 0.5s;
		opacity:1;
	}	
}	

.filter-left{
	padding: 20px 20px;
	width: 280px;
	position: fixed !important;
	left: -310px;
	top: 0;
	background: #fff;
	z-index: 9999999;
	box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
	overflow-y: scroll;
	height: 100%;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.filter-left
.filter-bar{
	top: 80px;
	position: sticky;
	z-index: 999;
}
.filter-bar.sticky-top .side-bar{
	z-index:9;
	top:0;
	position:unset;
}
.sticky-top.filter-bar{
	z-index:99999999;
	top: 80px;
	position: sticky;
}
.filter-left:after{
	content: "";
	position: fixed;
	width: 0;
	background: #fff;
	height: 100%;
	z-index: -1;
	left: 0;
	top: 0;
	opacity: 0;
	transition:all 0.5s;
	-moz-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-o-transition:all 0.5s;
}
.filter-left.filter-on:after{
	width: 100%;
	left: 280px;
	opacity: 0.8;
}
	
.filter-left.filter-on{
	left: 0;
}
.filter-bar{
	top: 80px;
	position: sticky;
	z-index: 999;
}
.filter-bar.sticky-top .side-bar{
	z-index:9;
	top:0;
	position:unset;
}
.sticky-top.filter-bar{
	z-index:99999999;
	top: 80px;
	position: sticky;
}
