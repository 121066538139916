/* ==================
	2.1 Font Size
=================== */
.fs10 { font-size: 10px; }
.fs12 { font-size: 12px; }
.fs13 { font-size: 13px; }
.fs14 { font-size: 14px; }
.fs16 { font-size: 16px; }
.fs18 { font-size: 18px; }
.fs20 { font-size: 20px; }
.fs22 { font-size: 22px; }
.fs24 { font-size: 24px; }
.fs26 { font-size: 26px; }
.fs30 { font-size: 30px; line-height:40px; }
.fs35 { font-size: 35px; line-height:45px; }
.fs40 { font-size: 40px; line-height:50px; }
.fs45 { font-size: 45px; line-height:55px; }
.fs50 { font-size: 50px; line-height:65px; }
.fs55 { font-size: 55px; line-height:65px; }
.fs60 { font-size: 60px; line-height:70px; }
.fs70 { font-size: 70px; line-height:80px; }
.fs75 { font-size: 75px; line-height:85px; }
.fs80 { font-size: 80px; line-height:90px; }
.fs90 { font-size: 90px;	line-height:100px; }
.fs100 { font-size: 100px; line-height:110px; }
/* ==================
	2.2 Font Weight 
=================== */
.fw3 { font-weight: 300; }
.fw4 { font-weight: 400; }
.fw5 { font-weight: 500; }
.fw6 { font-weight: 600; }
.fw7 { font-weight: 700; }
.fw8 { font-weight: 800; }
.fw9 { font-weight: 900; }
//	Lists
dl, ul, ol { list-style-position: outside;}
ul, ol { margin-bottom: 24px; }
ul li, ol li { padding: 0; }
dl { margin-left: 0; margin-bottom: 30px; }
dl dd { margin-bottom: 10px; }
/* ==================
	2.3 Positions Z-index
=================== */
.posi-ab { position: absolute; }
.posi-rl { position: relative; }
.posi-st { position: static; }
.zi1 { z-index: 1; }
.zi2 { z-index: 2; }
.zi3 { z-index: 3; }
.zi4 { z-index: 4; }
.zi5 { z-index: 5; }
.zi6 { z-index: 6; }
.zi7 { z-index: 7; }
.zi8 { z-index: 8; }
.zi9 { z-index: 9; }
.zi10 { z-index: 10; }
.zi100 { z-index: 100; }
.zi999 { z-index: 999; }
::selection { color: $white; background:$primary; }
::-moz-selection { color:$white; background:$primary; }
::-moz-selection { background: $primary; color: $white; }
::selection { background: $primary; color: $white; }
.mfp-gallery{list-style:none;margin-bottom: 0;}
.mfp-title{display:none;}
.list-inline li{display: inline-block;}
.full-img{width:100%;}
.sticky-top{top:130px;}
section{
    position: relative;
}
.ov-hidden{
	overflow:hidden;
}
/* ==================
	3. Spacing
=================== */
/* ==================
	3.1 Row Spacing
=================== */
.row.spno,
.spno{
	margin-left:0;
	margin-right:0;
	
	[class*="col"],
	[class*="col"]{
		padding-left:0;
		padding-right:0;
	}
}
.row.sp5,
.sp5{
	margin-left:-5px;
	margin-right:-5px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:5px;
		padding-right:5px;
	}
}
.row.sp10,
.sp10{
	margin-left:-10px;
	margin-right:-10px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:10px;
		padding-right:10px;
	}
}
.row.sp10,
.sp10{
	margin-left:-10px;
	margin-right:-10px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:10px;
		padding-right:10px;
	}
}
.row.sp20,
.sp20{
	margin-left:-20px;
	margin-right:-20px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:20px;
		padding-right:20px;
	}
}